import React, { Component } from "react";
import Results from "../../components/Results/Results";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Box, Spinner } from "@chakra-ui/core";
class ResultsContainer extends Component {
  state = {
    loading: true,
    results: [],
  };

  componentDidMount() {
    // let formdata = new FormData();
    // let formData2 = new FormData();
    // formdata.append("image", this.props.location.state.img);
    // formData2.append(
    //   "image_url",
    //   "https://homepages.cae.wisc.edu/~ece533/images/airplane.png"
    // );
    // formData2.append("variant_code", "abc");
    // axios
    //   .post("https://maruti.suzuki.xane.ai/users/pwa_scan", formdata, {
    //     headers: {
    //       "content-type": "multipart/form-data",
    //       "x-origin": 1,
    //     },
    //   })
    //   .then((response) => {
    //     let filteredResponses = response.data.data.filter(
    //       (obj) => obj !== null
    //     );
    //     this.setState({
    //       results: filteredResponses.splice(0, 3),
    //       loading: false,
    //     });
    //   });
    axios
      .post(
        "https://xanegin.xane.ai/query",
        {
          image_url: this.props.location.state.img,
          variant_code: "abc",
        },
        {
          headers: {
            "x-key":
              "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
          },
        }
      )
      .then((response) => {
        let arr = [];
        let obj = {};
        let intent = response.data.data.predictions[0].intent;
        obj.damage =
          intent == "damage_none"
            ? "No damage"
            : intent == "damage_mild"
            ? "Low"
            : intent == "damage_moderate"
            ? "Medium"
            : "High";
        obj.tyre_id = parseInt(Math.floor(Math.random() * 1000));
        obj.refurbishment_cost =
          intent == "damage_none"
            ? "0"
            : intent == "damage_mild"
            ? "5000"
            : intent == "damage_moderate"
            ? "50000"
            : "125000";
        obj.profit_percentage =
          intent == "damage_none"
            ? "20%"
            : intent == "damage_mild"
            ? "25%"
            : intent == "damage_moderate"
            ? "25%"
            : "25%";
        obj.purchase_value =
          intent == "damage_none"
            ? "INR 5L - 6L"
            : intent == "damage_mild"
            ? "INR 4L - 5L"
            : intent == "damage_moderate"
            ? "INR 2L - 3.5L"
            : "INR 25k - 40k";
        obj.certified_dealer_value =
          intent == "damage_none"
            ? "INR 6.5L - 8L"
            : intent == "damage_mild"
            ? "INR 5L - 7L"
            : intent == "damage_moderate"
            ? "INR 4.5L - 6L"
            : "INR 1L - 2.5L";
        obj.refurbishment_type =
          intent == "damage_none"
            ? "N/A"
            : intent == "damage_mild"
            ? "Scratch, Dent"
            : intent == "damage_moderate"
            ? "Bonnet Damage, Front Bumper Damage, Headlamp"
            : "Roof Damage, Door Panel Damage";
        arr = [...arr, obj];

        this.setState({ results: arr, loading: false });
      });
  }

  render() {
    const { results } = this.state;
    return this.state.loading ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#2b2b2b"
        h="100vh"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#2792BA"
          size="xl"
        />
      </Box>
    ) : (
      <div>
        <Results
          image={this.props.location.state.img}
          results={results ? results : []}
        />
      </div>
    );
  }
}

export default withRouter(ResultsContainer);
