import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Image } from "@chakra-ui/core";
import { Card } from "../custom/Card";
import './result.css';
const Results = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#2b2b2b",
        minHeight: "100%",
        paddingBottom: "1em",
        
      }}
    >
      <Image src={props.image} size="100%" />
      <Box
        color="#2792BA"
        alignSelf="center"
        justifyContent="center"
        display="flex"
        padding="1em"
        className="heading"
      >
        Scan results
      </Box>
      <Box d="flex" flexDirection="column" alignItems="center" p="1em">
      {/* {props.results.map((result,i) => {
        return <Card data={result} key={result.tyre_id} />;
      })} */}
      {props.results.map((result,i) => {
        return <Card data={result} key={i} />;
      })}
      </Box>
    </div>
  );
};

export default withRouter(Results);
