import React from "react";
import { Box, Image, Badge } from "@chakra-ui/core";
import "./card.css";
// export const Card = (props) => {
//   // const property = {
//   //   title: "Fuel Filter Warning Light",
//   //   description:
//   //     "There is water in the fuel filter, please drain it as soon as possible.",
//   //   file:
//   //     "https://s3.ap-south-1.amazonaws.com/maruti.data/Fuel_Filter_Warning_Light.png",
//   //   full_description:
//   //     "There is water in the fuel filter, please drain it as soon as possible. To see how to remove water please click on the link below or get your vehicle inspected by Maruti Suzuki Workshop.",
//   //   links:
//   //     "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Fuel_filter.mp4",
//   // };

//   const {title,description,file} = props.data;

//   return (
//     <Box
//       width="sm"
//       minH="150px"
//       display="flex"
//       borderWidth="1px"
//       rounded="lg"
//       overflow="hidden"
//       m="2em"
//       className="neumorphic"
//       flexDirection="row"
//     >
//       <Box p="4" w="30%">
//         <Image src={file} size="80px" alt='captured' />
//       </Box>

//       <Box d="flex" flexDirection="column" p="4">
//         <Box className="title">{title}</Box>
//         <br />
//         <Box className="description">{description}</Box>
//       </Box>
//     </Box>
//   );
// };

export const Card = (props) => {
  // const property = {
  //   title: "Fuel Filter Warning Light",
  //   description:
  //     "There is water in the fuel filter, please drain it as soon as possible.",
  //   file:
  //     "https://s3.ap-south-1.amazonaws.com/maruti.data/Fuel_Filter_Warning_Light.png",
  //   full_description:
  //     "There is water in the fuel filter, please drain it as soon as possible. To see how to remove water please click on the link below or get your vehicle inspected by Maruti Suzuki Workshop.",
  //   links:
  //     "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Fuel_filter.mp4",
  // };

  return (
    <Box
      width="sm"
      minH="150px"
      display="flex"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      m="2em"
      className="neumorphic"
    >
      {/* <Box p="4" w="30%">
        <Image src={file} size="80px" alt='captured' />
      </Box> */}

      <Box d="flex" flexDirection="column" p="4">
        <Box className="title" flexDirection="row">
          Damage :{" "}
          <span style={{ fontWeight: "normal" }}>{props.data.damage}</span>
        </Box>
        <Box className="title" flexDirection="row">
          Refurbishment Type :{" "}
          <span style={{ fontWeight: "normal" }}>
            {props.data.refurbishment_type}
          </span>
        </Box>
        <Box className="title" flexDirection="row">
          Refurbishment cost :{" "}
          <span style={{ fontWeight: "normal" }}>
            {props.data.refurbishment_cost}
          </span>
        </Box>
        <Box className="title" flexDirection="row">
          Purchase value :{" "}
          <span style={{ fontWeight: "normal", textTransform: "capitalize" }}>
            {props.data.purchase_value}
          </span>
        </Box>
        <Box className="title" flexDirection="row">
          Certified dealer value :{" "}
          <span style={{ fontWeight: "normal" }}>
            {props.data.certified_dealer_value}
          </span>
        </Box>
        <Box className="title" flexDirection="row">
          Profit percentage :{" "}
          <span
            style={{
              fontWeight: "normal",
            }}
          >
            {props.data.profit_percentage}
          </span>
        </Box>
        {/* <Box className="description">{description}</Box> */}
      </Box>
    </Box>
  );
};
