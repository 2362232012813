import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Box } from "@chakra-ui/core";
import { FaMicrophone } from "react-icons/fa";
import Siriwave from "react-siriwave";
import "./voice.css";
import Speech from "speak-tts";

const speech = new Speech(); // will throw an exception if not browser supported
if (speech.hasBrowserSupport()) {
  // returns a boolean
  console.log("speech synthesis supported");
}

speech
  .init()
  .then((data) => {
    // The "data" object contains the list of available voices and the voice synthesis params
    console.log("Speech is ready, voices are available");
  })
  .catch((e) => {
    console.error("An error occured while initializing : ", e);
  });

export const Voice = (props) => {
  const [message, setMessage] = useState("");
  const [showWave, setShowwave] = useState(false);

  const commands = [
    {
      command: "I would like to order *",
      callback: (food) => getResponse(`Your order is for: ${food}`),
    },
    {
      command: "The weather is :condition today",
      callback: (condition) =>
        getResponse(`Today, the weather is ${condition}`),
    },
    {
      command: "My top sports are * and *",
      callback: (sport1, sport2) => getResponse(`#1: ${sport1}, #2: ${sport2}`),
    },
    {
      command: "On Lights",
      callback: (command) => getResponse(command),
      isFuzzyMatch: true,
    },
    {
      command: "Pass the salt (please)",
      callback: () => getResponse("My pleasure"),
    },
    {
      command: "Hello",
      callback: () => getResponse("Hi there!"),
      //matchInterim: true,
    },
    {
      command: "Beijing",
      callback: (command, spokenPhrase, similarityRatio) =>
        getResponse(
          `${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`
        ),
      // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2,
    },
    {
      command: "clear",
      callback: ({ resetTranscript }) => resetTranscript(),
    },
  ];

  const { transcript, resetTranscript } = useSpeechRecognition({ commands });

  const getResponse = (msg) => {
    speech.speak({
      text: msg,
    });
    setMessage(msg);
  };

  useEffect(() => {
    SpeechRecognition.getRecognition().onspeechend = onSpeechEnd;
  });

  const onSpeechEnd = () => {
    setShowwave(false);
  };

  const processMessage = async () => {
    setShowwave(true);
    setMessage("");
    await SpeechRecognition.startListening();
  };

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  return (
    <div className="main">
      <p className="transcript">{transcript}</p>
      <p className="message">{message}</p>

      {showWave ? (
        <div style={{ position: "absolute", bottom: "20px" }}>
          <Siriwave style="ios9" cover />
        </div>
      ) : (
        <div
          className="mic_voice"
          onClick={() => {
            processMessage();
          }}
        >
          <Box as={FaMicrophone} size="28px" color="#fff" />
        </div>
      )}
    </div>
  );
};
