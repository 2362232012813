import React from "react";
import "./styles.css";
import { Box, Image } from "@chakra-ui/core";
import { FaCamera, FaMicrophone, FaRocketchat } from "react-icons/fa";
import {withRouter} from 'react-router-dom';
const Home = (props) => {
  return (
    <div className="main">
      <div className="camera" onClick={()=>{props.history.push('/camera')}}>
        <Box as={FaCamera} size="36px" color="#2792BA" />
      </div>
      <div className="mic" onClick={()=>{props.history.push('/voice')}}>
        <Box as={FaMicrophone} size="36px" color="#2792BA" />
      </div>
      <div className="text">
        <Box as={FaRocketchat} size="36px" color="#2792BA" />
      </div>
      <Box className="xane_icon">
        <Image
          src={require("./xane.png")}
          alt="Segun Adebayo"
          size="96px"
        />
      </Box>
    </div>
  );
};

export default withRouter(Home);
